<template>
  <div class="common-layout" v-if="refShow">
    <el-container>
      <el-header>
          <HeaderMenuRender></HeaderMenuRender>
      </el-header>
      <el-container>
        <!-- 初始时需设置 :default-openeds 否则不会默认展开 -->
        <HomePageLeftMenuRender v-if="!isEmptyArray(store.state.leftMenu)" ></HomePageLeftMenuRender>
        <el-container>
          <el-main>
            <HomePageTabs></HomePageTabs>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
import store from '../store'
import { onMounted, ref } from 'vue'
import { apiFetchData, MenuTree, getMaxId, isEmptyString, isEmptyArray, fillNullId, getOpenMenuIds, getCookie, isAdmin } from '../utils'
import { apiMenuPath } from '../config'

import HeaderMenuRender from '../components/HeaderMenuRender.vue'
import HomePageLeftMenuRender from '../components/HomePageLeftMenuRender.vue'
import HomePageTabs from './HomePageTabs.vue'

const refShow = ref(false)
onMounted(async () => {
  try {
    store.state.authorizationToken = getCookie('AuthorizationToken')
    store.state.isAdmin = isAdmin()
    store.state.userName = getCookie('UserName')
    store.state.userAccount = getCookie('UserAccount')

    const data = await apiFetchData(apiMenuPath)
    refShow.value = true
    let curId = getMaxId(data)
    function restructMenu (data: MenuTree[], depth: number) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].label === '$UserRightMenu$') {
          data[i].label = store.state.userName
        }
        if (isEmptyString(data[i].tag)) {
          if (depth === 0) {
            // 顶层菜单
            data[i].tag = 'el-menu-item'
          } else if (depth === 1) {
            // 左侧一级菜单
            data[i].tag = 'el-icon-menu-item'
          } else {
            // eslint-disable-next-line
            if (!isEmptyArray(data[i].children)) {
              // 有子菜单
              data[i].tag = 'el-sub-menu'
            } else {
              data[i].tag = 'el-menu-item'
            }
          }
        }
        if (isEmptyString(data[i].id)) {
          data[i].id = String(curId)
          curId = curId + 1
        }
        if (!isEmptyArray(data[i].children)) {
          restructMenu(data[i].children as MenuTree[], depth + 1)
        }
      }
    }
    restructMenu(data, 0)
    console.log('onMounted data=', data)
    store.state.meunTree = data
    // eslint-disable-next-line
    store.state.leftMenu = store.state.meunTree[0].children!
    store.state.leftMenuOpenedIds = getOpenMenuIds(store.state.meunTree)
    store.state.homeMenuIndex = data[0].id
  } catch (error) {
    console.error(error)
  }

  try {
    const data = await apiFetchData(store.state.editableTabs[0].url)
    console.log('data=', data)
    fillNullId(data)
    const resut: {[key: string]: string} = {}
    store.state.modelValues[store.state.editableTabs[0].name] = resut
    store.state.editableTabs[0].data = data
  } catch (error) {
    console.error(error)
  }
})
</script>

<style scoped>
.el-header, .el-footer {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: right;
  line-height: 10px;
}
.el-main {
  background-color: #ffffff;
  color: #333;
  text-align: right;
  line-height: 10px;
  --el-main-padding: 5px;
  overflow: visible;
}
</style>
