<template>
  <div class="demo-image">
    <div class="block">
      <el-image style="width: 400px; height: 313px" :src="refImageUrl" fit="fill" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineProps, onMounted, ref } from 'vue'
import { rootImagePath } from '../config'

export default defineComponent({
  name: 'ImageItem'
})
</script>
<script lang="ts" setup>
const props = defineProps({
  dataValue: {
    type: String,
    require: true
  }
})

const refImageUrl = ref('')

onMounted(async () => {
  console.log('onMounted props.dataValue=', props.dataValue)
  if ((props.dataValue.startsWith('http://') || props.dataValue.startsWith('https://'))) {
    refImageUrl.value = props.dataValue
  } else {
    refImageUrl.value = rootImagePath + '/' + props.dataValue
  }
})
</script>

<style scoped>
.demo-image .block {
  padding: 0px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  width: 0%;
  box-sizing: border-box;
  vertical-align: top;
}
.demo-image .block:last-child {
  border-right: none;
}
</style>
