<template>
  <el-menu
    :default-active="store.state.homeMenuIndex"
    class="el-menu-demo"
    mode="horizontal"
    :ellipsis="false"
    @select="handleSelect"
  >
    <el-menu-item :index="store.state.homeMenuIndex" style="padding:0px">
      <img
        style="width: 150px"
        src="/images/logo.png"
        alt="Newtech Life"
      />
    </el-menu-item>
    <MenuItemRender :item=item v-for="(item) in store.state.meunTree" :key="item.id"></MenuItemRender>
  </el-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MenuItemRender from './MenuItemRender.vue'
import store from '../store'
import { findTreeNode, MenuTree, isEmptyArray } from '../utils'
import { loginPath, rootEnglishPath, rootChinesePath } from '../config'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'HeaderMenuRender'
})
</script>

<script lang="ts" setup>
const handleSelect = (key:string, keyPath:string[]) => {
  console.log('handleSelect key=', key)
  console.log('handleSelect keyPath=', keyPath)
  if (key === 'logout_menu') {
    document.cookie = 'AuthorizationToken=; Max-Age=-99999999;'
    window.location.href = loginPath
  } else if (key === 'switch_english_menu') {
    window.location.href = rootEnglishPath
  } else if (key === 'switch_chinese_menu') {
    window.location.href = rootChinesePath
  }
  // 只有 tag 是 el-menu-item 的菜单才可点击
  // 横向菜单，是 el-menu-item， 同时有 子菜单， 则 子菜单 挂在到 左侧
  // 横向菜单，是 el-sub-menu， 响应 handleSelect 时， 只有子菜单是 el-menu-item 的子菜单， 才会响应
  const result = findTreeNode(store.state.meunTree, key, '', 0)
  if (result === null) {
    return
  }
  const menu = result.node as MenuTree
  if (isEmptyArray(menu.children)) {
    return
  }
  store.state.leftMenu = menu.children as MenuTree[]
}

</script>

<style>
.flex-grow {
  flex-grow: 1;
}
</style>
