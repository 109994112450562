<template>
  <el-row :gutter="20" justify="center" align = "middle">
    <el-col :span="1" align="left"><div class="grid-content ep-bg-purple" />
      <el-radio-group v-model="store.state.leftIsCollapse" style="margin-bottom: 0px">
        <el-radio-button :label="false" v-if="store.state.leftIsCollapse==true"><el-icon><Expand /></el-icon></el-radio-button>
        <el-radio-button :label="true" v-if="store.state.leftIsCollapse==false"><el-icon><Fold /></el-icon></el-radio-button>
      </el-radio-group>
    </el-col>
    <el-col :span="23" type="flex"  align="left">&nbsp;{{store.state.showPath}}</el-col>
  </el-row>
  <el-divider class="home-page-divider"/>
  <el-tabs
    v-model="store.state.editableTabsValue"
    type="card"
    class="demo-tabs"
    @tab-remove="removeTab"
    @tab-click="onClick"
  >
    <el-tab-pane
      v-for="item in store.state.editableTabs"
      :key="item.name"
      :label="getI18n(item.title)"
      :name="item.name"
      :closable="item.closable"
    >
      <div>
        <span class="tab-title">
        </span>
        <div>
          <template v-if="isEmptyString(item.bindPage)" >
            <ViewRender :tabName="item.name" :item=subItem v-for="(subItem) in item.data.children" :key="subItem.index"></ViewRender>
          </template>
          <template v-else-if="item.bindPage === 'EditMenuPage'">
            <EditMenuPage :item=item></EditMenuPage>
          </template>
          <template v-else-if="item.bindPage === 'EditPagePage'">
            <EditPagePage :tabName="item.name" :item=item></EditPagePage>
          </template>
          <template v-else-if="item.bindPage === 'EditMenuPrivilegePage'">
            <EditMenuPrivilegePage :tabName="item.name" :item=item></EditMenuPrivilegePage>
          </template>
          <template v-else-if="item.bindPage === 'EditUserProfilePage'">
            <EditUserProfilePage :item=item></EditUserProfilePage>
          </template>
          <template v-else-if="item.bindPage === 'ChangePasswordPage'">
            <ChangePasswordPage :item=item></ChangePasswordPage>
          </template>
          <template v-else>
            <p></p>
            <h5>功能暂未实现</h5>
          </template>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ViewRender from '../components/ViewRender.vue'
import EditMenuPage from './administor/EditMenuPage.vue'
import EditPagePage from './administor/EditPagePage.vue'
import EditMenuPrivilegePage from './administor/EditMenuPrivilegePage.vue'
import EditUserProfilePage from './EditUserProfilePage.vue'
import ChangePasswordPage from './ChangePasswordPage.vue'
import { isEmptyString, getI18n } from '../utils'

import store from '../store'
// 解决vs中的语法错误提示
export default defineComponent({
  name: 'HomePageTabs'
})
</script>
<script lang="ts" setup>

const removeTab = (targetName:string) => {
  store.dispatch('removeTab', targetName)
}
const onClick = (pane:object, ev:object) => {
  store.dispatch('tabClick', { pane: pane, ev: ev })
}
</script>
<style>
.demo-tabs > .el-tabs__content {
  padding: 5px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
  text-align: left;
}
.home-page-divider {
  margin: 0px !important;
  height: 0px !important;
}
.tab-title {
  line-height: 50px !important;
}
.el-tabs__header {
  margin: 2px !important;
}
.el-row {
  margin: 10px !important;
}
</style>
