<template>
  <template v-for="(event, index) in itemEvents.eventList" :key="index">
      <el-divider style="margin:0px" />
          <template v-if="event.isExpand">
          <el-col class="attr-label">
              <el-row class="attr-row">
              <el-text type="info">按钮标题:</el-text>
              <div class="flex-grow" />
              <el-button type="info" :icon="ArrowUpBold" @click="expandItem(index)" style="height:24px !important"  plain />
              </el-row>
          </el-col>
          <el-col>
              <el-input v-model="event.label" />
          </el-col>

          <el-col class="attr-label">
              <el-text type="info">服务器接口:</el-text><br>
          </el-col>
          <el-col>
              <el-input v-model="event.targetUrl" />
          </el-col>

          <el-col class="attr-label">
              <el-text type="info">是否需要确认窗口:</el-text><br>
          </el-col>
          <el-col>
              <el-switch
              v-model="event.isConfirm"
              style="--el-switch-on-color: #2196F3; --el-switch-off-color: #B0B0B0"
              />
          </el-col>

          <el-col class="attr-label">
              <el-text type="info">显示编辑窗口:</el-text><br>
          </el-col>
          <el-col>
              <el-switch
              v-model="event.isShowEditDlg"
              style="--el-switch-on-color: #2196F3; --el-switch-off-color: #B0B0B0"
              />
          </el-col>
          <el-col class="attr-label">
              <el-text type="info">编辑窗口标题:</el-text><br>
          </el-col>
          <el-col>
              <el-input v-model="event.editDlgTitle" />
          </el-col>
          <el-col class="attr-label">
              <el-text type="info">编辑窗口【确定】按钮名称:</el-text><br>
          </el-col>
          <el-col>
              <el-input v-model="event.editOkBtnName" />
          </el-col>
          <el-col class="attr-label">
              <el-text type="info">关联字段:</el-text><br>
          </el-col>
          <el-col>
              <el-input v-model="event.fieldName" />
          </el-col>
          <el-col class="attr-label">
              <el-text type="info">字段值条件:</el-text><br>
          </el-col>
          <el-col>
              <el-input v-model="event.fieldValue" />
          </el-col>
          <el-col>
              <el-row type="flex" class="mb-2" justify="center">
              <template v-if="event.isNewEvent">
                  <el-button type="primary" class="full-width-button" style="flex-grow: 1;" @click="addItemEvent()">添加事件</el-button>
              </template>
              <template v-else>
                  <el-button type="primary" class="full-width-button" style="flex-grow: 1;" @click="deleteItemEvent(index)">删除事件</el-button>
              </template>
              </el-row>
          </el-col>
          </template>
          <template v-else>
              <el-col class="attr-label">
              <el-row class="attr-row">
                  <el-text type="info">事件:{{event.isNewEvent?'添加新事件':event.label}}</el-text>
                  <div class="flex-grow" />
                  <el-button type="info" :icon="ArrowDownBold" @click="expandItem(index)" style="height:24px !important" plain />
              </el-row>
              </el-col>
          </template>
  </template>
</template>

<script lang="ts">
import { defineComponent, defineProps, onMounted } from 'vue'
import {
  ArrowUpBold,
  ArrowDownBold
} from '@element-plus/icons-vue'
import { TableItemEvents } from '../utils'

export default defineComponent({
  name: 'TableItemEventEditor'
})
</script>

<script lang="ts" setup>

const props = defineProps({
  itemEvents: {
    type: Object as () => TableItemEvents,
    require: true
  }
})

// 添加事件
function addItemEvent () {
  // eslint-disable-next-line
  if (props.itemEvents!.eventList.length >= 1) {
    // eslint-disable-next-line
    const i = props.itemEvents!.eventList.length - 1
    // eslint-disable-next-line
    props.itemEvents!.eventList[i].isNewEvent = false
  }
  // eslint-disable-next-line
  props.itemEvents!.eventList.push({
    label: '',
    targetUrl: '',
    isShowEditDlg: false,
    isConfirm: true,
    fieldName: '',
    fieldValue: '',
    isNewEvent: true,
    isExpand: false
  })
}
// 删除事件
function deleteItemEvent (index: number) {
  // eslint-disable-next-line
  props.itemEvents!.eventList.splice(index, 1)
}
function expandItem (index: number) {
  // eslint-disable-next-line
  props.itemEvents!.eventList[index].isExpand = !props.itemEvents!.eventList[index].isExpand
  // eslint-disable-next-line
  console.log('isExpand=' + props.itemEvents!.eventList[props.itemEvents!.eventList.length - 1].isExpand)
}
onMounted(async () => {
  // eslint-disable-next-line
  if (props.itemEvents!.eventList.length === 0) {
    addItemEvent()
  }
})

</script>
<style scoped>
.attr-row {
  margin: 0px;
  padding: 0px;
  text-align: left;
}
.attr-label {
  margin-bottom: 5px;
}
.flex-grow {
  flex-grow: 1;
}
.full-width-button {
  flex-grow: 1; /* 填充整行 */
  /* padding-top: 20px;  */ /* 增加上边距 */
  /* padding-bottom: 20px;  */ /* 保持按钮垂直方向上的平衡，可选 */
  /* 其他你需要的样式 */
  margin-top: 5px;
}
</style>
