<template>
  <el-row style="margin:0px;">
    <!-- 顶部工具栏-菜单树 -->
    <el-col :span="refTreeMenuSpan">
      <el-checkbox v-model="refIsShowMenuTree" label="菜单树" @change="recaculateSpan" size="large" />
    </el-col>
    <!-- 顶部工具栏-组件栏 -->
    <el-col :span="refComponetToolSpan">
      <el-checkbox v-model="refIsShowMenuTree" label="菜单树" @change="recaculateSpan" size="large" v-if="!refTreeMenuSpan" />
      <el-checkbox v-model="refIsShowComponetTool" label="组件栏" @change="recaculateSpan" size="large" />
    </el-col>
    <!-- 顶部工具栏-预览 -->
    <el-col :span="refEditWindowSpan">
      <el-row style="margin:0px;">
        <el-col :span="18">
            <el-checkbox v-model="refIsShowMenuTree" label="菜单树" @change="recaculateSpan" size="large" v-if="!refTreeMenuSpan && !refComponetToolSpan" />
            <el-checkbox v-model="refIsShowComponetTool" label="组件栏" @change="recaculateSpan" size="large" v-if="!refComponetToolSpan"/>
            <el-checkbox v-model="refIsPreview" label="预览" @change="onPreview" size="large" />
            <el-text type="info">&nbsp;&nbsp;{{refSelectFullPath}}</el-text>
        </el-col>
        <el-col :span="6" align="right">
          <el-button type="primary" @click="savePage()">保存</el-button>&nbsp;&nbsp;
          <el-checkbox v-model="refIsAtrrWindow" label="属性" @change="recaculateSpan" size="large" v-if="!refAtrrWindowSpan"/>
        </el-col>
      </el-row>
    </el-col>
    <!-- 顶部工具栏-属性 -->
    <el-col :span="refAtrrWindowSpan">
      <el-checkbox v-model="refIsAtrrWindow" label="属性" @change="recaculateSpan" size="large" />
    </el-col>
  </el-row>
  <el-row :gutter="12" class="demo-radius">
    <!-- 菜单树 -->
    <el-col :span="refTreeMenuSpan">
      <div class="radius" :style="`var(--el-border-radius-base)`" >
        <el-tree :data="treeData" @node-click="onNodeClick" />
      </div>
    </el-col>
    <!-- 组件栏 -->
    <el-col :span="refComponetToolSpan" >
      <div class="radius" :style="`var(--el-border-radius-base)`" >
        <ComponentList :itemList="refTools"></ComponentList>
      </div>
    </el-col>
    <!-- 编辑区 -->
    <el-col :span="refEditWindowSpan">
        <template v-if="!refIsPreview">
          <PageEditor :tabName="tabName" :item="store.state.pageItems"></PageEditor>
        </template>
        <template v-else>
          <div id="edit-div" class="radius" :style="`var(--el-border-radius-base)`" >
            <ViewRender :tabName="tabName" :item=subItem v-for="(subItem) in store.state.pageItems.children" :key="subItem.tag"></ViewRender>
          </div>
        </template>
    </el-col>
    <!-- 属性区 -->
    <el-col :span="refAtrrWindowSpan">
      <div class="radius" :style="`var(--el-border-radius-base)`" >
        <el-row class="attr-row">
          <el-col class="attr-label">
            <el-text type="info">id:</el-text><br>
          </el-col>
          <el-col>
            <el-input v-model="store.state.selectItemInDraggable.id" disabled />
          </el-col>
        </el-row>
        <template v-for="(subItem) in getEditAttr()" :key="subItem.id">
          <el-row class="attr-row">
            <template v-if="stringEqual(subItem.action,'refresh')">
              <el-col>
                <el-button type="primary" @click="onRefresh">{{subItem.label}}</el-button>
              </el-col>
            </template>
            <template v-else>
              <el-col class="attr-label">
                <el-text type="info">{{subItem.label}}:</el-text><br>
              </el-col>
              <el-col>
                <template v-if="subItem.tag=='TableItemEventEditor'">
                  <TableItemEventEditor :itemEvents="store.state.selectItemInDraggable[subItem.field]" style="margin=0px;"/>
                </template>
                <template v-else>
                  <component :is="subItem.tag" v-model="store.state.selectItemInDraggable[subItem.field]"></component>
                </template>
              </el-col>
            </template>
          </el-row>
        </template>
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import store from '../../store'
import type Node from 'element-plus/es/components/tree/src/model/node'
import { defineComponent, ref, onMounted, defineProps, ComponentInternalInstance, getCurrentInstance } from 'vue'
import { apiFetchData, MenuTree, apiPostData, findTreeNode, fillNullId, deepCopy, isEmptyString, isEmptyArray, BaseTree, stringEqual, showRequestResult } from '../../utils'
import { apiToolComponentPath, apiSavePagePath, apiGetPageJsonPath, emptyPage } from '../../config'
import ComponentList from './ComponentList.vue'
import PageEditor from '../../components/PageEditor.vue'
import ViewRender from '../../components/ViewRender.vue'
import TableItemEventEditor from '../../components/TableItemEventEditor.vue'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'EditMenuPage'
})
</script>

<script lang="ts" setup>
// 是否显示菜单树
const refIsShowMenuTree = ref(true)
// 是否显示组件工具栏
const refIsShowComponetTool = ref(true)
// 预览
const refIsPreview = ref(false)
// 是否显示属性窗口
const refIsAtrrWindow = ref(true)

// 菜单树的span
const refTreeMenuSpan = ref(4)
// 组件工具栏的span
const refComponetToolSpan = ref(4)
// 页面编辑创库的span
const refEditWindowSpan = ref(12)
// 属性窗口的span
const refAtrrWindowSpan = ref(4)

// 组件
const refTools = ref([])

// 选中的菜单的全路径
const refSelectFullPath = ref('')

// 重新计算各区域的span
const recaculateSpan = () => {
  if (refIsShowMenuTree.value) {
    refTreeMenuSpan.value = 4
  } else {
    refTreeMenuSpan.value = 0
  }
  if (refIsShowComponetTool.value) {
    refComponetToolSpan.value = 4
  } else {
    refComponetToolSpan.value = 0
  }
  if (refIsAtrrWindow.value) {
    refAtrrWindowSpan.value = 4
  } else {
    refAtrrWindowSpan.value = 0
  }
  console.log('recaculateSpan refAtrrWindowSpan=', refAtrrWindowSpan.value)
  refEditWindowSpan.value = 24 - refTreeMenuSpan.value - refComponetToolSpan.value - refAtrrWindowSpan.value
}

const onPreview = () => {
  console.log('onPreview store.state.pageItems=', store.state.pageItems)
}

// 保存到服务器
const savePage = () => {
  const path = refPageUrl.value.replace('__get_page', apiSavePagePath)
  apiPostData(path, store.state.pageItems)
    .then(data => {
      console.log('savePage data=', data)
      showRequestResult(data, '保存', '保存', '保存结果')
    })
}
// 菜单绑定的页面返回的页面数据，在这里是菜单树
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  tabName: {
    type: String,
    required: true
  }
})

// 当前菜单关联的页面的url
const refPageUrl = ref('')

// 点击菜单树节点
// 参考 DragTreePage.vue 的分析过程
const onNodeClick = (data: MenuTree, node: Node, nodeInstance: ComponentInternalInstance, e: MouseEvent) => {
  const result = findTreeNode(treeData.value, data.id, '', 0)
  if (result === null || result.depath < 2 ||
    (!isEmptyArray(result.node.children))) {
    // 没找到， 层级小于2（顶层菜单是0）， 有子菜单都不能挂在页面
    console.log('onNodeClick 不能编辑页面 result=', result)
    return
  }
  refSelectFullPath.value = result.fullPath
  let url = data.url
  if (isEmptyString(url)) {
    url = `${apiGetPageJsonPath}${data.id}.json`
  }
  console.log(`onNodeClick url=${url}`)
  refPageUrl.value = url
  apiFetchData(url)
    .then(data => {
      console.log('onNodeClick 1 data=', data)
      if (data === null) {
        data = deepCopy(emptyPage)
      }
      // store.state.curItemId = fillNullId(data)
      fillNullId(data)
      console.log('onNodeClick 2 data=', data)
      store.state.pageItems = data
      if (isEmptyArray(data.children)) {
        store.state.selectItemInDraggable = {}
      } else {
        store.state.selectItemInDraggable = data.children[0]
      }
    })
}

// 菜单树的数据，
const treeData = ref([] as MenuTree[])

// 重新组织菜单树
function structMenu () {
  const mainMenus = [] as MenuTree[]
  console.log('props.item=', props.item)
  for (let i = 0; i < props.item.data.length; i++) {
    if (props.item.data[i].tag === 'menu-placeholder') {
      break
    }
    mainMenus[i] = props.item.data[i]
  }
  treeData.value = mainMenus
}

function onRefresh () {
  console.log('onRefresh enter')
  const instance = getCurrentInstance()
  console.log('onRefresh instance=', instance)
  if (instance) {
    instance.proxy?.$forceUpdate?.()
    console.log('onRefresh forceUpdate')
  }
}

function getEditAttr () {
  const tag = (store.state.selectItemInDraggable as {[tag:string]:string}).tag
  console.log('getEditAttr tag=', tag)
  for (let i = 0; i < refTools.value.length; i++) {
    console.log('getEditAttr tag=', (refTools.value[i] as{[item:string]: string}).item)
    const item = (refTools.value[i] as{[item:string]: object}).item
    if (tag === (item as {[tag:string]: string}).tag) {
      return (item as {[attrs:string]: object}).attrs
    }
  }
  return [] as object[]
}

onMounted(async () => {
  try {
    console.log('EditMenuPage onMouted')
    store.state.pageItems = {} as BaseTree
    store.state.selectItemInDraggable = {} as BaseTree
    structMenu()
    refTools.value = await apiFetchData(apiToolComponentPath)
    console.log('EditPagePage onMounted refTools=', refTools)
  } catch (error) {
    console.error(error)
  }
})
</script>

<style scoped>
.demo-radius .title {
  color: var(--el-text-color-regular);
  font-size: 18px;
  margin: 10px 0;
}
.demo-radius .value {
  color: var(--el-text-color-primary);
  font-size: 16px;
  margin: 10px 0;
}
.demo-radius .radius {
  width: 100%;
  border: 1px solid var(--el-border-color);
  border-radius: 0;
  margin: 0px;
}
.attr-row {
  margin: 0px;
}
.attr-label {
  margin-bottom: 5px;
}
</style>
