<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <draggable
    :list="item.children"
    @start="start"
    @end="end"
    @add="add"
    @click="click"
    group="itxst"
    animation="300"
    :style="isEmptyString(item.draggableStyle)?item.draggableStyle:emptyPage.draggableStyle"
    @change="log"
    item-key="id"
    class="pageInner"
    :class="item.draggableClass"
  >
    <template #item="{ element }">
      <div :id="element.id" class="list-group-item" :class="element.draggableDivClass">
        <ViewRender :tabName="tabName" :isEditPageMode="true" :item="element" :key="element.id"></ViewRender>
      </div>
    </template>
  </draggable>
</template>

<script lang="ts">
import store from '../store'
import { defineComponent, defineProps } from 'vue'
import draggable from 'vuedraggable'
import ViewRender from './ViewRender.vue'
import { DraggableEvent, findParentWithAttribute, isEmptyString, findTreeNode, BaseTree } from '../utils'
import { emptyPage } from '../config'
// 解决vs中的语法错误提示
export default defineComponent({
  name: 'PageEditor'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  tabName: {
    type: String,
    required: true
  }
})

// 移除元素
const removeItem = (e:DraggableEvent, itemHeight:number) => {
  // 获取拖动元素的位置信息
  const x = e.originalEvent.clientX
  const y = e.originalEvent.clientY

  // 获取容器的位置信息
  const container = e.to
  const containerRect = container.getBoundingClientRect()
  const bottom = containerRect.bottom + itemHeight

  // 判断拖动元素是否在容器范围内
  if (x < containerRect.left || x > containerRect.right || y < containerRect.top || y > bottom) {
    // 拖动到了区域外
    console.log('removeItem 拖动到了区域外 item=', props.item.children[e.newDraggableIndex])
    // eslint-disable-next-line vue/no-mutating-props
    props.item.children.splice(e.newDraggableIndex, 1)
    store.state.selectItemInDraggable = {} as BaseTree
  } else {
    // 拖动在容器范围内
    console.log('removeItem item=', props.item.children[e.newDraggableIndex])
    store.state.selectItemInDraggable = props.item.children[e.newDraggableIndex]
    console.log('removeItem 拖动在容器范围内')
  }
}
// @click="click" 只是鼠标原始的点击事件， 没有 拖拽节点的数据属性
const click = (e: PointerEvent) => {
  console.log('click enter e=', e)
  const result = findParentWithAttribute(e.target as HTMLElement, 'data-draggable', null)
  if (result !== null) {
    // eslint-disable-next-line
    const elementId = result.getAttribute('id')
    // eslint-disable-next-line
    const nodeInfo = findTreeNode(store.state.pageItems, elementId!, '', 0)
    // eslint-disable-next-line
    store.state.selectItemInDraggable = nodeInfo!.node
  }
}

// 右边往左边拖动时的事件
const end = (e: DraggableEvent) => {
  console.log('end enter --------------------------------')
  removeItem(e, 0)
  console.log('end leave --------------------------------')
}
// 右边往左边拖动时的事件
const start = (e: DraggableEvent) => {
  console.log('start running --------------------------------')
}
// move回调方法
const add = (e: DraggableEvent) => {
  console.log('add enter e=', e)
  const itemHeight = e.item !== null ? e.item.clientHeight : 10
  removeItem(e, itemHeight)
  console.log('add leave -----------------------')
}
const log = (event: object) => {
  console.log('log enter -----------------------')
  console.log(event)
  console.log('log leave -----------------------')
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<style>
.pageInner {
  width: 98%;
  flex: 1;
  padding: 0px;
  border: solid 1px #514949;
  border-radius: 5px;
}
</style>
