<template>
  <div style="margin: 10px;">
    <el-form label-width="auto" style="max-width: 600px">
      <el-form-item :label="getI18n('账户')+':'">
        <el-text class="mx-1">{{store.state.userAccount}}</el-text>
      </el-form-item>
      <el-form-item :label="getI18n('用户名')+':'">
        <el-row class="full-width" :gutter="0" justify="start" style="margin: 0px !important;">
          <template v-if="refIsEditUserName">
            <el-col :span="20">
              <el-input v-model="refUserName" />
            </el-col>
            <el-col :span="4">
              <el-button :icon="Check" circle v-if="refIsEditUserName" @click="save" />
            </el-col>
          </template>
          <template v-else>
            <el-col :span="20" class="left-align">
              <el-text class="mx-1">{{refUserName}}</el-text>
            </el-col>
            <el-col :span="4">
              <el-button :icon="Edit" circle v-if="!refIsEditUserName" @click="startEdit" />
            </el-col>
          </template>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts">
import store from '../store'
import { ref, defineComponent, onMounted } from 'vue'
import { apiPostData, showRequestResult, getI18n } from '../utils'
import {
  Edit,
  Check
} from '@element-plus/icons-vue'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'EditUserProfilePage'
})
</script>

<script lang="ts" setup>
const refIsEditUserName = ref(false)
const refUserName = ref('')

onMounted(async () => {
  refUserName.value = store.state.userName
})
const startEdit = () => {
  refIsEditUserName.value = true
}
const save = () => {
  refIsEditUserName.value = false
  store.state.userName = refUserName.value
  const postData = { userName: refUserName.value }
  console.log('postData=', postData)
  apiPostData('/action?__action_id=__update_user_name', postData)
    .then(data => {
      showRequestResult(data, '保存成功', '保存失败', '', false, true, false)
    })
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
.right-align {
  display: flex;
  justify-content: flex-end; /* 使内容元素右对齐 */
}
.left-align {
  display: flex;
  justify-content: flex-start; /* 使内容元素右对齐 */
}
</style>
