<template>
  <el-row id="menu-tree" :gutter="12" class="demo-radius">
    <el-col :span="8" :xs="{ span: 12 }">
      <el-text class="mx-1" type="primary">{{getI18n('角色')}}</el-text>
    </el-col>
    <el-col :span="14" :xs="{ span: 12 }" >
      <el-row>
      <el-col :span="18">
        <el-text class="mx-1" type="primary">{{getI18n('菜单')}}</el-text>
      </el-col>
      <el-col :span="6" align="right">
          <el-button type="primary" @click="saveMenuPrivilege(true)">{{getI18n('保存')}}</el-button>&nbsp;&nbsp;
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  <el-row :gutter="12" class="demo-radius">
    <el-col :span="8" :xs="{ span: 12 }">
      <div class="radius" :style="`var(--el-border-radius-base)`" >
        <div v-for="(item, index) in refRoleList" :key="item">
          <el-text class="mx-1 text_center box" :class="{'selected-box': item['selected'] }" @click="selectRole(index)" >{{getI18n(item.role_name)}}</el-text>
        </div>
      </div>
    </el-col>
    <el-col :span="14" :xs="{ span: 12 }" >
      <div class="radius" :style="`var(--el-border-radius-base)`" >
        <el-tree :data="refTreeData" node-key="id" show-checkbox ref="treeRef" :props="defaultProps" @check-change="handleCheckChange"/>
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted, defineProps } from 'vue'
import { ElMessage, ElTree } from 'element-plus'
import { apiPostData, apiFetchData, MenuTree, isEmptyArray, isEmptyString, getI18n, i18nTreeData, parseResponse } from '../../utils'
import { apiAdminLoadRolePath, apiAdminSaveRoleMenusPath } from '../../config'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'EditMenuPage'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const defaultProps = {
  children: 'children',
  label: 'label'
}

const saveMenuPrivilege = (succesPopMessage:boolean) => {
  if (refRoleList.value.length === 0) {
    return
  }
  const id = refRoleList.value[currentIndex].id
  const roleMenus = JSON.stringify(refRoleList.value[currentIndex].roleMenus)
  const postData = { roleMenus: roleMenus, id: id }
  console.log('postData=', postData)
  apiPostData(apiAdminSaveRoleMenusPath, postData)
    .then(data => {
      console.log('handleCheckChange data=', data)
      const result = parseResponse(data)
      if ((succesPopMessage && result.success) || !result.success) {
        ElMessage({
          type: result.success ? 'success' : 'error',
          message: data.success ? '保存成功' : '保存失败:' + result.message
        })
      }
    })
}

const refRoleList = ref([])
let currentIndex = 0

function pushSelectId (data) {
  if (!refRoleList.value[currentIndex].roleMenus.includes(data.id)) {
    refRoleList.value[currentIndex].roleMenus.push(data.id)
  }
  if (isEmptyArray(data.children)) {
    return
  }
  for (let i = 0; i < data.children.length; i++) {
    pushSelectId(data.children[i])
  }
}
const handleCheckChange = (
  data: MenuTree,
  checked: boolean,
  indeterminate: boolean
) => {
  console.log(data, checked, indeterminate)
  if (checked) {
    pushSelectId(data)
  } else {
    refRoleList.value[currentIndex].roleMenus = refRoleList.value[currentIndex].roleMenus.filter(item => item !== data.id)
  }
  // 频繁触发，所以不在这里保存
  // saveMenuPrivilege(false)
}

function selectRole (index) {
  if (refRoleList.value.length === 0) {
    return
  }
  if (!('roleMenus' in refRoleList.value[index])) {
    if (!isEmptyString(refRoleList.value[index].role_menus)) {
      refRoleList.value[index].roleMenus = JSON.parse(refRoleList.value[index].role_menus)
    } else {
      refRoleList.value[index].roleMenus = []
    }
  }
  // 把之前的先保存一下
  saveMenuPrivilege(false)
  currentIndex = index
  for (let i = 0; i < refRoleList.value.length; i++) {
    refRoleList.value[i].selected = i === index
  }
  treeRef.value.setCheckedKeys(refRoleList.value[index].roleMenus, false)
}
// 菜单树的数据，
const refTreeData = ref([] as MenuTree[])
const treeRef = ref<InstanceType<typeof ElTree>>()

// 重新组织菜单树
function structMenu () {
  const mainMenus = [] as MenuTree[]
  i18nTreeData(props.item.data)
  console.log('props.item=', props.item)
  for (let i = 0, j = 0; i < props.item.data.length; i++) {
    if (props.item.data[i].tag === 'menu-placeholder') {
      continue
    }
    mainMenus[j] = props.item.data[i]
    j++
  }
  refTreeData.value = mainMenus
}
onMounted(async () => {
  try {
    console.log(props.item.data)
    console.log('EditMenuPage onMouted')
    const data = await apiFetchData(apiAdminLoadRolePath)
    refRoleList.value = data.data
    selectRole(0)
    structMenu()
  } catch (error) {
    console.error(error)
  }
})
</script>

<style scoped>
.demo-radius .title {
  color: var(--el-text-color-regular);
  font-size: 18px;
  margin: 10px 0;
}
.demo-radius .value {
  color: var(--el-text-color-primary);
  font-size: 16px;
  margin: 10px 0;
}
.demo-radius .radius {
  width: 100%;
  border: 1px solid var(--el-border-color);
  border-radius: 0;
  margin: 0px;
}
.box {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin: 10px;
  text-align: left;
  border-radius: 4px;
  border-color:  rgba(200, 230, 190, 1);
  border: 1px solid lightgrey;
}
.selected-box {
  background-color:  rgba(228, 240, 222, 0.8);
}
</style>
