<template>
  <el-row id="menu-tree" :gutter="12" class="demo-radius">
    <el-col :span="10" :xs="{ span: 12 }" >
      <div class="radius" :style="`var(--el-border-radius-base)`" >
        <el-tree
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          :data="treeData"
          draggable
          node-key="id"
          :expand-on-click-node="false"
          @node-click="onNodeClick"
          @node-expand="onNodeExpand"
          @node-collapse="onNodeCollapse"
          @node-drop="onNodeDrop"
          ref="treeRef"
        />
      </div>
    </el-col>
    <el-col :span="14" :xs="{ span: 12 }">
      <div id="edit-div" class="radius" :style="`var(--el-border-radius-base)`" >
        <el-row>
          <el-col :span="6" align="right"><el-text class="mx-1">全路径:&nbsp;</el-text></el-col>
          <el-col :span="18"><el-input disabled v-model="refSelectFullPath" placeholder="" /></el-col>
        </el-row>
        <el-row>
          <el-col :span="6" align="right"><el-text class="mx-1">唯一标识:&nbsp;</el-text></el-col>
          <el-col :span="18"><el-input disabled v-model="refSelectIndex" placeholder="" /></el-col>
        </el-row>
        <el-row>
          <el-col :span="6" align="right"><el-text class="mx-1">标题:&nbsp;</el-text></el-col>
          <el-col :span="18"><el-input v-model="refSelectLabel" placeholder="请输入标题" /></el-col>
        </el-row>
        <el-row>
          <el-col :span="6" align="right"><el-text class="mx-1">绑定页面(非必填):&nbsp;</el-text></el-col>
          <el-col :span="18"><el-input v-model="refBindPage" placeholder="绑定页面" /></el-col>
        </el-row>
        <el-row>
          <el-col :span="6" align="right"><el-text class="mx-1">页面数据地址(非必填):&nbsp;</el-text></el-col>
          <el-col :span="18"><el-input v-model="refUrl" placeholder="页面数据地址" /></el-col>
        </el-row>
        <template v-if="refSelectNodeDepth === 1">
          <el-row>
            <el-col :span="6" align="right"><el-text class="mx-1">选择图标:&nbsp;</el-text></el-col>
            <el-col :span="10"><el-input  v-model="refSelectIcon" placeholder="选择图标" /></el-col>
            <el-col :span="8" align="right"><a href="https://element-plus.org/zh-CN/component/icon.html#icon-collection" target="blank"><el-text class="mx-1">打开网站直接复制图标名称</el-text></a></el-col>
          </el-row>
        </template>
        <el-row>
          <el-col :span="24" align="right">
            <template v-if="isAddMenu()">
              <el-button type="primary" @click="addNewMenu">添加菜单</el-button>
            </template>
            <template v-else-if="!isEmptyString(refSelectIndex)">
              <el-button type="primary" @click="saveMenu('')">保存菜单</el-button>
              <el-button type="primary" @click="deleteMenu">删除菜单</el-button>
            </template>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import type Node from 'element-plus/es/components/tree/src/model/node'
import { ref, defineComponent, onMounted, defineProps, ComponentInternalInstance } from 'vue'
import { ElMessageBox } from 'element-plus'
import { apiPostData, MenuTree, findTreeNode, getMaxId, isEmptyArray, isEmptyString, showRequestResult } from '../../utils'
import { apiSaveMenuPath } from '../../config'
import type {
  AllowDropType
} from 'element-plus/es/components/tree/src/tree.type'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'EditMenuPage'
})
</script>

<script lang="ts" setup>
const treeRef = ref()
const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const allowDrop = (draggingNode: Node, dropNode: Node, type: AllowDropType) => {
  console.log('allowDrop draggingNode=', draggingNode)
  if (dropNode.data.isAddNewMenu) {
    if (type === 'inner' || type === 'next') {
      return false
    }
  }
  return true
}
const allowDrag = (draggingNode: Node) => {
  return !draggingNode.data.isAddNewMenu
}

function save2Server (message: string) {
  const copyData = [] as MenuTree[]
  // 删除原数据中 添加新菜单节点
  function deletAddNewMenuNode (copyData: MenuTree[], srcData: MenuTree[]) {
    for (let i = 0; i < srcData.length; i++) {
      if (srcData[i].isAddNewMenu) {
        break
      }
      copyData[i] = {
        id: srcData[i].id,
        label: srcData[i].label,
        tag: '',
        url: srcData[i].url,
        icon: srcData[i].icon,
        bindPage: srcData[i].bindPage,
        children: undefined,
        isAddNewMenu: srcData[i].isAddNewMenu,
        isDefaultOpen: srcData[i].isDefaultOpen
      }
      if (!isEmptyArray(srcData[i].children)) {
        // 递归重新组织 子菜单
        copyData[i].children = []
        deletAddNewMenuNode(copyData[i].children as MenuTree[], srcData[i].children as MenuTree[])
      }
    }
  }
  deletAddNewMenuNode(copyData as MenuTree[], treeData.value)

  apiPostData(apiSaveMenuPath, copyData)
    .then(data => {
      console.log('save2Server data=', data)
      console.log('save2Server selectNode=', selectNode)
      showRequestResult(data, message, message + '失败:')
    })
}
const addNewMenu = () => {
  // 修改原节点数据
  saveMenu('添加菜单保存到服务器')

  console.log('addNewMenu 1 selectNode=', selectNode)

  const parentNode = selectNode.parent
  if (parentNode !== null) {
    if (!isEmptyArray(parentNode.data.children)) {
      parentNode.data.children[parentNode.data.children.length] = addNewMenuNode()
    } else {
      // 根节点
      parentNode.data[parentNode.data.length] = addNewMenuNode()
    }
  }
  console.log('addNewMenu 2 selectNode=', selectNode)
  console.log('addNewMenu 1 parentNode=', parentNode)
}

const saveMenu = (message: string) => {
  const data = selectNode.data
  data.id = refSelectIndex.value
  data.label = refSelectLabel.value
  data.icon = refSelectIcon.value
  data.isAddNewMenu = false
  data.bindPage = refBindPage.value
  data.url = refUrl.value

  if (isEmptyArray(data.children)) {
    data.children = [addNewMenuNode()]
  }
  const result = findTreeNode(treeData.value, data.id, '', 0)
  if (result !== null) {
    refSelectFullPath.value = result.fullPath
  }

  message = message || '修改菜单保存到服务器'
  save2Server(message)
}
// 是否有子节点
function isHaveChildren (data:MenuTree) {
  console.log('isHaveChildren data.children=', data.children)
  if (isEmptyArray(data.children)) {
    console.log('isHaveChildren return false 2')
    return false
  }
  // 只需检查第一个元素
  // eslint-disable-next-line
  if (data.children![0].isAddNewMenu) {
    console.log('isHaveChildren return false 1')
    return false
  }
  console.log('isHaveChildren return true')
  return true
}

function deleteMenu () {
  if (isHaveChildren(selectNode.data as MenuTree)) {
    ElMessageBox.confirm(
      '有子菜单，你确认要删除吗?',
      '删除菜单',
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }
    )
      .then(() => {
        executeDeleteMenu()
      })
      .catch(() => {
        console.log('deleteMenu cancel')
      })
  } else {
    executeDeleteMenu()
  }
}
const executeDeleteMenu = () => {
  console.log('删除菜单')

  const parent = selectNode.parent
  const children: MenuTree[] = parent.data.children || parent.data
  const id = children.findIndex((d) => d.id === refSelectIndex.value)
  children.splice(id, 1)

  selectNode = {} as Node
  refSelectIndex.value = ''
  refSelectLabel.value = ''
  refSelectIcon.value = ''
  save2Server('删除菜单保存到服务器')
}

const getNodeY = (nodeInstance: ComponentInternalInstance) => {
  // eslint-disable-next-line
  const nodeRect = nodeInstance.vnode.el!.getBoundingClientRect()
  return nodeRect.y
}

const moveEditDiv = (node: Node, nodeY: number) => {
  console.log('moveEditDiv node=', node)
  selectNode = node
  const data = node.data
  refSelectIndex.value = data.id
  refSelectLabel.value = data.label
  refSelectIcon.value = data.icon
  refBindPage.value = data.bindPage
  refUrl.value = data.url
  console.log('moveEditDiv refSelectIndex=', refSelectIndex)

  const result = findTreeNode(treeData.value, data.id, '', 0)
  if (result !== null) {
    refSelectFullPath.value = result.fullPath
    refSelectNodeDepth.value = result.depath
  }

  // eslint-disable-next-line
  const menuTree = document.getElementById('menu-tree')!
  // eslint-disable-next-line
  const editDiv = document.getElementById('edit-div')!
  // eslint-disable-next-line
  const treeRect = menuTree.getBoundingClientRect()!

  editDiv.style.position = 'relative'
  let top = nodeY - treeRect.top - editDiv.offsetHeight / 2
  if (top + editDiv.offsetHeight > treeRect.height) {
    top = treeRect.height - editDiv.offsetHeight
  }
  if (top < 0) {
    top = 0
  }
  editDiv.style.top = top + 'px'
}
// 参考 DragTreePage.vue 的分析过程
const onNodeClick = (data: MenuTree, node: Node, nodeInstance: ComponentInternalInstance) => {
  console.log('onNodeClick nodeInstance=', nodeInstance)
  const nodeY = getNodeY(nodeInstance)
  moveEditDiv(node, nodeY)
}
const onNodeExpand = (data: MenuTree, node: Node, nodeInstance: ComponentInternalInstance) => {
  const nodeY = getNodeY(nodeInstance)
  moveEditDiv(node, nodeY)
}
const onNodeCollapse = (data: MenuTree, node: Node, nodeInstance: ComponentInternalInstance) => {
  const nodeY = getNodeY(nodeInstance)
  moveEditDiv(node, nodeY)
}
const onNodeDrop = (dragNode: Node, dragEnterNode: Node, posistion:string, e: DragEvent) => {
  moveEditDiv(dragNode, e.y)
}

// 当前选中节点的数据
let selectNode = {} as Node // 当前选中节点
const refSelectIndex = ref('')
const refSelectFullPath = ref('')
const refSelectLabel = ref('')
const refSelectIcon = ref('')
const refBindPage = ref('')
const refUrl = ref('')
const refSelectNodeDepth = ref(0) // 当前选中节点的深度

const isAddMenu = () => {
  if (refSelectIndex.value === '') {
    return false
  }
  console.log('isAddMenu refSelectIndex=', refSelectIndex)
  return selectNode.data.isAddNewMenu
}

const treeData = ref([] as MenuTree[])

let menuIndex = 10000

const addNewMenuNode = () => {
  menuIndex++
  return {
    id: String(menuIndex),
    label: '[添加新菜单]...',
    url: '',
    isAddNewMenu: true
  } as MenuTree
}
onMounted(async () => {
  try {
    console.log('EditMenuPage onMouted')
    const mainMenus = [] as MenuTree[]
    for (let i = 0; i < props.item.data.length; i++) {
      if (props.item.data[i].tag === 'menu-placeholder') {
        break
      }
      mainMenus[i] = props.item.data[i]
    }
    menuIndex = getMaxId(mainMenus)
    // 重新组织， 添加上必要的数据
    function restructMenu (data: MenuTree[], depth: number) {
      const length = data.length
      if (length === 0) {
        data[length] = addNewMenuNode()
        return
      }
      for (let i = 0; i < length; i++) {
        data[i].isAddNewMenu = false
        if (isEmptyString(data[i].id)) {
          // 添加 id
          menuIndex++
          data[i].id = String(menuIndex)
        }
        // eslint-disable-next-line
        if (!isEmptyArray(data[i].children)) {
          // 递归重新组织 子菜单
          restructMenu(data[i].children as MenuTree[], depth + 1)
        } else if (depth < 2 || isEmptyString(data[i].url)) {
          // 添加 添加新菜单 节点
          data[i].children = [addNewMenuNode()]
        }
        if (i === length - 1) {
          // 添加 添加新菜单 节点
          data[length] = addNewMenuNode()
        }
      }
    }
    restructMenu(mainMenus, 0)
    treeData.value = mainMenus
  } catch (error) {
    console.error(error)
  }
})
</script>

<style scoped>
.demo-radius .title {
  color: var(--el-text-color-regular);
  font-size: 18px;
  margin: 10px 0;
}
.demo-radius .value {
  color: var(--el-text-color-primary);
  font-size: 16px;
  margin: 10px 0;
}
.demo-radius .radius {
  width: 100%;
  border: 1px solid var(--el-border-color);
  border-radius: 0;
  margin: 0px;
}
</style>
