<template>
  <el-menu
    default-active=""
    class="el-menu-vertical-demo"
    :collapse="store.state.leftIsCollapse"
    @open="handleOpen"
    @close="handleClose"
    @select="handleSelect"
    :default-openeds="store.state.leftMenuOpenedIds"
  >
    <MenuItemRender :item=leftItem v-for="(leftItem) in store.state.leftMenu" :key="leftItem.id"></MenuItemRender>
  </el-menu>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import MenuItemRender from './MenuItemRender.vue'
import store from '../store'
import { findTreeNode, MenuTree } from '../utils'
// 解决vs中的语法错误提示
export default defineComponent({
  name: 'HomePageLeftMenuRender'
})
</script>

<script lang="ts" setup>
// const isCollapse = ref(true)
// eslint-disable-next-line
const handleOpen = (key: string, keyPath: string[]) => {
  // console.log('handleOpen ', key, keyPath)
}
// eslint-disable-next-line
const handleClose = (key: string, keyPath: string[]) => {
  // console.log('handleClose ', key, keyPath)
}
const handleSelect = (key: string, keyPath: string[]) => {
  // 添加展示页
  const result = findTreeNode(store.state.meunTree, key, '', 0)
  const menu = result?.node as MenuTree
  store.dispatch('addTab', { menuId: menu.id, targetName: menu.label, fullPath: result?.fullPath, url: menu.url, bindPage: menu.bindPage })
}
onMounted(async () => {
  console.log('HomePageLeftMenuRender onMounted store.state.leftMenuOpenedIds=', store.state.leftMenuOpenedIds)
})
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu-item, .el-sub-menu__title, .menu-title {
  height: 35px !important;
  vertical-align: middle !important;
}
</style>
