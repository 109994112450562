<template>
  <el-select
    v-model="refValue"
    filterable
    placeholder="Select"
    style="width: 100%;"
>
    <el-option
    v-for="item in refOptions"
    :key="item.value"
    :label="item.label"
    :value="item.value"
    />
  </el-select>
</template>

<script lang="ts">
import { defineComponent, ref, defineProps, onMounted } from 'vue'
import { apiFetchData } from '../utils'

export default defineComponent({
  name: 'DropDownSelector'
})
</script>

<script lang="ts" setup>
const props = defineProps({
  dataValue: {
    type: String,
    require: true
  },
  srcDataUrl: {
    type: String,
    require: true
  }
})

const refValue = ref('')
const refOptions = ref([])
// mounted时
onMounted(async () => {
  console.log('onMounted props.dataValue=', props.dataValue)
  console.log('onMounted props.srcDataUrl=', props.srcDataUrl)
  const data = await apiFetchData(props.srcDataUrl)
  refOptions.value = data.data
  refValue.value = props.dataValue
})
</script>
