import { createStore } from 'vuex'
import { apiFetchData, MenuTree, deepCopy, BaseTree, getLanguage, getI18n } from '../utils'
import { apiReadMePath, apiGetPageJsonPath, emptyPage } from '../config'

export default createStore({
  state: {
    meunTree: [] as MenuTree[],
    // 左侧菜单是否折叠
    leftIsCollapse: false,
    // 左侧菜单
    leftMenu: [] as MenuTree[],
    // 默认展开的菜单id
    leftMenuOpenedIds: [] as string[],

    // 首页菜单索引
    homeMenuIndex: '',

    // 显示的菜单路径
    showPath: '/说明',

    // 改为使用时间错作为 组件实例 id
    // 当前编辑页面的 元素 id
    // curItemId: 10000,

    // 使用时间戳作为 组件实例 id
    currentTimestamp: Date.now(),
    // 为了防止重复的索引
    currentTimestampIndex: 0,

    // 当前编辑页面数据
    pageItems: {} as BaseTree,
    // 页面编辑控件中当前选中的
    selectItemInDraggable: {},

    // 正在编辑的tab页
    editableTabsValue: '说明',
    editableTabs: [
      {
        title: '说明',
        name: '说明',
        content: '说明',
        url: apiReadMePath,
        data: {},
        closable: false,
        bindPage: null
      }
    ],
    // 每个table页绑定的model数据
    modelValues: {} as {[key: string]: object},
    // 语言
    language: '',
    // 国际化
    i18n: {} as {[key: string]: string},

    // 从cookie解析出来的token
    authorizationToken: '',
    isAdmin: false,
    userName: ''
  },
  getters: {
  },
  mutations: {
    setLeftMenuStatus (state, status) {
      state.leftIsCollapse = status
    }
  },
  actions: {
    init () {
      this.state.language = getLanguage()
      const statement = getI18n('说明', false)
      this.state.showPath = '/' + statement
      this.state.editableTabsValue = statement
      this.state.editableTabs = [
        {
          title: statement,
          name: statement,
          content: statement,
          url: apiReadMePath,
          data: {},
          closable: false,
          bindPage: null
        }
      ]
    },
    addTab (context, payload) {
      const targetName = payload.targetName
      const fullPath = payload.fullPath

      // 激活原来的
      const tabs = this.state.editableTabs
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i]
        if (tab.name === fullPath) {
          this.state.editableTabsValue = fullPath
          this.state.showPath = fullPath
          return
        }
      }
      let url = payload.url
      if (!payload.url) {
        url = `${apiGetPageJsonPath}${payload.menuId}.json`
      }
      try {
        apiFetchData(url)
          .then(data => {
            if (!data) {
              data = deepCopy(emptyPage)
            }
            console.log('addTab data=', data)
            const resut: {[key: string]: string} = {}
            this.state.modelValues[fullPath] = resut
            this.state.editableTabs.push({
              title: targetName,
              name: fullPath,
              content: fullPath,
              closable: true,
              url: payload.url,
              data: data,
              bindPage: payload.bindPage
            })
            this.state.editableTabsValue = fullPath
            this.state.showPath = fullPath
          })
          .catch(error => console.error(error))
      } catch (error) {
        console.error(error)
      }
    },
    removeTab (context, targetName) {
      const tabs = this.state.editableTabs
      let activeName = this.state.editableTabsValue
      if (activeName === String(targetName)) {
        tabs.forEach((tab, index) => {
          if (tab.name === String(targetName)) {
            const nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeName = nextTab.name
            }
          }
        })
      }
      this.state.editableTabsValue = activeName
      this.state.showPath = activeName
      this.state.editableTabs = tabs.filter((tab) => tab.name !== String(targetName))
    },
    tabClick (context, payload) {
      this.state.showPath = payload.pane.props.name
    }
  },
  modules: {
  }
})
