<template>
<div class="container" v-if="refShow">
  <div class="login-register">
    <el-form :model="refLoginForm" ref="loginFormRef" :rules="refLoginRules" label-width="80px" class="login-form">
      <el-form-item :label="getI18n('用户名')" prop="account">
        <el-input v-model="refLoginForm.account" :placeholder="getI18n('请输入') + getI18n('用户名')"></el-input>
      </el-form-item>
      <el-form-item :label="getI18n('密码')" prop="password">
        <el-input type="password" v-model="refLoginForm.password" :placeholder="getI18n('请输入') + getI18n('密码')"></el-input>
      </el-form-item>
      <el-form-item v-if="refLoginForm.errorMessage!==''">
        <el-text class="mx-1" type="danger">{{refLoginForm.errorMessage}}</el-text>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login">{{getI18n('登录')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from 'vue'
import { apiPostData, getI18n } from '../utils'
import { apiLoginPath } from '../config'

// 解决vs中的语法错误提示
export default defineComponent({
  name: 'LoginView'
})
</script>
<script lang="ts" setup>
const refLoginForm = ref({
  account: '',
  password: '',
  errorMessage: ''
})
const refLoginRules = ref({
  account: [
    { required: true, message: getI18n('请输入') + getI18n('用户名'), trigger: 'blur' }
  ],
  password: [
    { required: true, message: getI18n('请输入') + getI18n('密码'), trigger: 'blur' }
  ]
})
const refShow = ref(false)
const loginFormRef = ref()

function login () {
  console.log(refLoginForm.value.password)
  loginFormRef.value.validate(valid => {
    if (valid) {
      apiPostData(apiLoginPath, { account: refLoginForm.value.account, password: refLoginForm.value.password })
        .then(response => {
          console.log('login', response)
          let success = true
          if (response === undefined || response === null) {
            // 在 apiPostData 中跳转是 response 为空
            // refLoginForm.value.errorMessage = 'unknow error'
            success = false
          } else {
            success = response.success
            refLoginForm.value.errorMessage = response.errmsg
          }
          if (success) {
            window.location.href = '/'
          }
        })
    } else {
      console.log('登录表单验证失败')
      return false
    }
  })
}
onMounted(async () => {
  refShow.value = true
})
</script>

<style scoped>
.login-register {
  width: 400px;
  border-style: solid;
  border-width: 1px;
  border-color: gainsboro;
  justify-content: center;
}

.login-form, .register-form {
  margin: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
</style>
