<template>
  <template v-if = "isEditPageMode && isTrue(item.is_container)">
    <PageEditor :tabName="tabName" :item="item"></PageEditor>
  </template>
  <template v-else>
    <template v-if = "!isEmptyString(item.tag) && item.tag === 'el-col'">
      {{item}}
    </template>
    <component :is="item.tag" v-bind="item" v-if = "!isEmptyString(item.tag)" :isEditPageMode="isEditPageMode" v-model="store.state.modelValues[tabName][item.id]">{{item.title}}
      <template #[getSlot] v-if = "isSlot()">
        <ViewRender :tabName="tabName" :item=subItem v-for="(subItem) in item.children[0].children" :key="subItem.title">{{subItem}}</ViewRender>
      </template>
      <template v-if = "isSlot()">
        <template v-for="(subItem, index) in item.children" :key="subItem.tag">
          <ViewRender :tabName="tabName" :item=subItem v-if = "index>0" :key="subItem.tag"></ViewRender>
        </template>
      </template>
      <template v-if = "!isSlot()">
        <ViewRender :tabName="tabName" :isEditPageMode="isEditPageMode" :item=subItem v-for="(subItem) in item.children" :key="subItem.tag"></ViewRender>
      </template>
    </component>
    <template v-else>
      {{item.title}}
    </template>
  </template>
</template>

<script lang="ts">
import store from '../store'
import { defineComponent, defineAsyncComponent, defineProps } from 'vue'
import { isEmptyArray, isTrue, isEmptyString } from '../utils'
import EditColumnTable from './EditColumnTable.vue'
import PageDescription from './PageDescription.vue'

export default defineComponent({
  name: 'ViewRender',
  components: {
    EditColumnTable,
    PageDescription,
    PageEditor: defineAsyncComponent(() => import('./PageEditor.vue'))
  }
})
</script>

<script lang="ts" setup>
// import PageEditor from './PageEditor.vue'
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  isEditPageMode: {
    type: Boolean,
    required: false,
    default: false
  },
  tabName: {
    type: String,
    required: true
  }
})

const isSlot = () => {
  // eslint-disable-next-line
  const currentItem = props.item
  if (isEmptyArray(currentItem.children)) {
    return false
  }
  // eslint-disable-next-line
  const subItem = currentItem.children[0]
  if (subItem.tag !== 'template' || isEmptyString(subItem.slot_name)) {
    return false
  }
  return true
}
const getSlot = () => {
  // eslint-disable-next-line
  const currentItem = props.item
  // eslint-disable-next-line
  const subItem = currentItem.children[0]
  return subItem.slot_name
}
</script>
