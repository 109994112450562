import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { getLanguage, apiFetchData } from './utils'
import { apiGetI18nPath } from './config'

const language = getLanguage()
console.log('main language=', language)
const app = createApp(App)
app.use(store)
app.use(router)
if (language.startsWith('zh')) {
  app.use(ElementPlus, { locale: zhCn })
} else {
  app.use(ElementPlus)
}

store.state.i18n = await apiFetchData(apiGetI18nPath)
store.dispatch('init', {})

console.log('store.state.i18n=', store.state.i18n)

// 参考 https://element-plus.gitee.io/zh-CN/component/icon.html#%E4%BD%BF%E7%94%A8%E5%8C%85%E7%AE%A1%E7%90%86%E5%99%A8
// 中导入所有图标并进行全局注册。
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.mount('#app')

app.config.warnHandler = function (msg, vm, trace) {
  // 忽略特定警告
  if (msg.includes('Failed setting prop "children" on')) {
    return
  }
  if (msg.includes('Extraneous non-props attributes')) {
    return
  }
  // 默认的警告处理
  console.warn(msg, vm, trace)
}
