<template>
    <el-menu-item :index="item.id" v-if = "item.tag == 'el-menu-item'">{{getI18n(item.label)}}</el-menu-item>
    <div class="flex-grow" v-else-if = "item.tag == 'menu-placeholder'"></div>
    <el-sub-menu :index="item.id" v-else-if = "item.tag == 'el-sub-menu'">
        <template #title>{{getI18n(item.label)}}</template>
        <MenuItemRender :item=subItem v-for="(subItem) in item.children" :key="subItem.id"></MenuItemRender>
    </el-sub-menu>
    <el-sub-menu :index="item.id" v-else-if = "item.tag == 'el-icon-menu-item'">
        <template #title>
          <el-icon><component :is="item.icon" /></el-icon>
          <span>{{getI18n(item.label)}}</span>
        </template>
        <MenuItemRender :item=subItem v-for="(subItem) in item.children" :key="subItem.id"></MenuItemRender>
    </el-sub-menu>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'
import { getI18n } from '../utils'
// 解决vs中的语法错误提示
export default defineComponent({
  name: 'MenuItemRender'
})
</script>

<script lang="ts" setup>
defineProps({
  item: Object
})
</script>
