<template>
  <router-view/>
</template>

<script lang="ts">
// Vue 学习笔记 错误ResizeObserver loop completed with undelivered notifications
// https://blog.csdn.net/xjcwzp/article/details/133081709
import { defineComponent } from 'vue'
export default defineComponent({
  setup () {
    const debounce = (callback: (...args: object[]) => void, delay: number) => {
      let tid: number
      return function (...args: object[]) {
        const ctx = self
        tid && clearTimeout(tid)
        tid = setTimeout(() => {
          callback.apply(ctx, args)
        }, delay)
      }
    }

    // eslint-disable-next-line
    const _ = (window as any).ResizeObserver;
    (window as {ResizeObserver: object}).ResizeObserver = class ResizeObserver extends _ {
      constructor (callback: (...args: object[]) => void) {
        callback = debounce(callback, 20)
        super(callback)
      }
    }
  }
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
